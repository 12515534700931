import React, { Component } from 'react'
import { graphql } from 'gatsby'

import withContext from '../components/withContext'
import injectContext from '../components/injectContext'
import withIntl from '../components/withIntl'
import Space from '../components/Space'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Navigation from '../scenes/Navigation'
import Header from '../scenes/Article/Header'
import Content from '../scenes/Article/Content'
import Footer from '../scenes/Footer'

class Article extends Component {

  render() {
    const {
      data: {
        markdownRemark: {
          frontmatter: {
            slug,
            title,
            description,
            tag,
            authorPicture,
            authorName,
            date,
          },
          html,
        },
      },
    } = this.props

    return (
      <Layout>
        <SEO
          metaTitle={title}
          metaDescription={description}
          metaOGType="article"
        />
        <Navigation />
        <Header
          slug={slug}
          title={title}
          tag={tag}
          authorPicture={authorPicture}
          authorName={authorName}
          date={date}
        />
        <Space type="l" />
        <Content
          html={html}
        />
        <Footer />
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query BlogArticleByLocaleAndSlug($locale: String!, $slug: String!) {
    markdownRemark(frontmatter: { lang: { eq: $locale }, slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        description
        tag
        authorPicture
        authorName
        date
      }
      html
    }
  }
`

export default withContext(injectContext(withIntl(Article)))
