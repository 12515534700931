import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-grid-system'

import MarkdownContainer from '../../../components/MarkdownContainer'
import Space from '../../../components/Space'

class Content extends Component {

  render() {
    const {
      html,
    } = this.props

    return (
      <Container>
        <Row>
          <Col xs={12} md={10} offset={{ md: 1 }}>
            <MarkdownContainer html={html} />
            <Space type="xxl" />
          </Col>
        </Row>
      </Container>
    )
  }
}

Content.propTypes = {
  html: PropTypes.node.isRequired,
}

Content.defaultProps = {
  html: undefined,
}

export default Content
