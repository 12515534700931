import './style.css'
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system'

import Breadcrumb from '../../../components/Breadcrumb'
import Image from '../../../components/Image'
import Inline from '../../../components/Inline'
import Space from '../../../components/Space'
import Tag from '../../../components/Tag'

const containerClass = classNames({
  'article-header-container': true,
})

const authorContainerClass = classNames({
  'article-header-author-container': true,
})

const authorTextClass = classNames({
  'article-header-author-text': true,
})

class Header extends Component {

  render() {
    const {
      title,
      tag,
      authorPicture,
      authorName,
      date,
    } = this.props

    const picturePath = require(`../../../img/team/${authorPicture}`)
    const dateText = new Date(Date.parse(date)).toLocaleDateString()

    return (
      <Container>
        <Hidden xs sm>
          <Row>
            <Col xs={12}>
              {this.renderBreadcrumb()}
            </Col>
          </Row>
        </Hidden>
        <Row>
          <Col md={8} offset={{ md: 2 }}>
            <div className={containerClass}>
              <Space type="xxs" />
              <Tag name={tag} />
              <Space type="s" />
              <h1>
                {title}
              </h1>
              <Space type="s" />
              <div className={authorContainerClass}>
                <Image
                  src={picturePath}
                  height={24}
                  width={24}
                />
                <Inline type="xxs" />
                <p className={authorTextClass}>
                  <FormattedMessage
                    id="blog.posts.post.authorDate"
                    values={{
                      name: <b>{authorName}</b>,
                      date: <b>{dateText}</b>,
                    }}
                  />
                </p>
              </div>

            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  renderBreadcrumb = () => {
    const {
      title,
      slug,
      intl: {
        formatMessage,
      },
    } = this.props
    const crumbs = [
      { name: formatMessage({ id: 'breadcrumb.wuha' }), path: '/' },
      { name: formatMessage({ id: 'breadcrumb.blog' }), path: '/blog' },
      { name: title, path: `${slug}` },
    ]

    return (
      <Fragment>
        <Space type="m" />
        <Breadcrumb content={crumbs} />
        <Space type="m" />
      </Fragment>
    )
  }
}

Header.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  authorPicture: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
}

Header.defaultProps = {
  slug: undefined,
  title: undefined,
  tag: undefined,
  authorPicture: undefined,
  authorName: undefined,
  date: undefined,
  intl: undefined,
}

export default injectIntl(Header)
